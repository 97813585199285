<template>
	<div id="main-wrapper" class="inner-pages dep-page">
		<div class="section banner-holder">
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>{{$t('depositWithdrawalPage.heading')}}</h2>
				<p>{{$t('depositWithdrawalPage.headingDescription')}}</p>
			</div>
		</div>
		<div class="section dep-content">
			<div class="wrapper">
				<div class="title-holder t-left wow fadeIn t-center">
					<p>{{$t('depositWithdrawalPage.subHeading')}}</p>
				</div>
				<div class="listing wow fadeIn">
					<div class="info-holder">
						<h3>{{$t('depositWithdrawalPage.subHeadingPointOne')}}</h3>
						<p>{{$t('depositWithdrawalPage.subHeadingPointOneDesc')}}</p>
					</div>
					<div class="info-holder">
						<h3>{{$t('depositWithdrawalPage.subHeadingPointTwo')}}</h3>
						<p>{{$t('depositWithdrawalPage.subHeadingPointTwoDesc')}}</p>
					</div>
					<div class="info-holder">
						<h3>{{$t('depositWithdrawalPage.subHeadingPointThree')}}</h3>
						<p>{{$t('depositWithdrawalPage.subHeadingPointThreeDesc')}}</p>
					</div>
					<div class="info-holder">
						<br>
						<h3>{{$t('depositWithdrawalPage.subHeadingPointFour')}}</h3>
						<p>{{$t('depositWithdrawalPage.subHeadingPointFourDesc')}}</p>
					</div>
					<div class="info-holder">
						<br>
						<h3>{{$t('depositWithdrawalPage.subHeadingPointFive')}}</h3>
						<p>{{$t('depositWithdrawalPage.subHeadingPointFiveDesc')}}</p>
					</div>
					<div class="info-holder">
						<br>
						<h3>{{$t('depositWithdrawalPage.subHeadingPointSix')}}</h3>
						<p>{{$t('depositWithdrawalPage.subHeadingPointSixDesc')}}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="section deposits-holder" v-if="showtab == 'withdrawal'">
			<div class="wrapper">
				<div class="title-holder t-center wow fadeIn">
					<h3>{{$t('depositWithdrawalPage.WithdrawalUpperCase')}}</h3>
					<p>{{$t('depositWithdrawalPage.WithdrawalDesc')}}</p>
				</div>
				<div class="title-holder t-left wow fadeIn">
					<p>{{$t('depositWithdrawalPage.StepsToWithdrawal')}}:<br>
					1. {{$t('depositWithdrawalPage.StepsToWithdrawalPointOne')}} <br>
					2. {{$t('depositWithdrawalPage.StepsToWithdrawalPointTwo')}} <br>
					3. {{$t('depositWithdrawalPage.StepsToWithdrawalPointThree')}} <br>
					</p>
				</div>
				<div class="deposit-nav">
					<ul>
						<li> <a v-on:click="showtab = 'deposit'" href="#" >{{$t('depositWithdrawalPage.Deposit')}}</a> </li>
						<li class="active"> <a href="#" v-on:click="showtab = 'withdrawal'"  >{{$t('depositWithdrawalPage.Withdrawals')}}</a> </li>
					</ul>
				</div>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>{{$t('depositWithdrawalPage.BankCards')}}</th>
							<th>{{$t('depositWithdrawalPage.Region')}}</th>
							<th>{{$t('depositWithdrawalPage.Currencies')}}</th>
							<th>{{$t('depositWithdrawalPage.MinDeposit')}}</th>
							<th>{{$t('depositWithdrawalPage.ServiceFee')}}</th>
							<th>{{$t('depositWithdrawalPage.ProcessingTime')}}</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/dep1.webp" alt="AAAFx" title="AAAFx" width="105" height="31" class="fluid-img" /> </td>
							<td>{{$t('depositWithdrawalPage.WorldWide')}}</td>
							<td>{{$t('depositWithdrawalPage.EUR')}}, {{$t('depositWithdrawalPage.USD')}}, {{$t('depositWithdrawalPage.CHF')}}, {{$t('depositWithdrawalPage.GBP')}}</td>
							<td>${{$t('depositWithdrawalPage.zero')}}</td>
							<td>${{$t('depositWithdrawalPage.zero')}}</td>
							<td>{{$t('depositWithdrawalPage.instantCaps')}}</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">{{$t('depositWithdrawalPage.fundCaps')}}</a> </td>
						</tr>
					</table>
				</div>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>{{$t('depositWithdrawalPage.BankWire')}}</th>
							<th>{{$t('depositWithdrawalPage.Region')}}</th>
							<th>{{$t('depositWithdrawalPage.Currencies')}}</th>
							<th>{{$t('depositWithdrawalPage.MinDeposit')}}</th>
							<th>{{$t('depositWithdrawalPage.ServiceFee')}}</th>
							<th>{{$t('depositWithdrawalPage.ProcessingTime')}}</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/dep2.webp" alt="AAAFx" title="AAAFx" width="94" height="28" class="fluid-img" /> </td>
							<td>{{$t('depositWithdrawalPage.WorldWide')}}</td>
							<td>{{$t('depositWithdrawalPage.USD')}}</td>
							<td>${{$t('depositWithdrawalPage.zero')}}</td>
							<td>${{$t('depositWithdrawalPage.zero')}}</td>
							<td>{{$t('depositWithdrawalPage.twoToThreeBusinessDays')}}</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">{{$t('depositWithdrawalPage.fundCaps')}}</a> </td>
						</tr>
					</table>
				</div>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>{{$t('depositWithdrawalPage.BankWire')}}</th>
							<th>{{$t('depositWithdrawalPage.Region')}}</th>
							<th>{{$t('depositWithdrawalPage.Currencies')}}</th>
							<th>{{$t('depositWithdrawalPage.MinDeposit')}}</th>
							<th>{{$t('depositWithdrawalPage.ServiceFee')}}</th>
							<th>{{$t('depositWithdrawalPage.ProcessingTime')}}</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/e4.webp" alt="AAAFx" title="AAAFx" width="59" height="21" class="fluid-img" /> </td>
							<td>{{$t('depositWithdrawalPage.WorldWide')}}</td>
							<td>{{$t('depositWithdrawalPage.EUR')}}, {{$t('depositWithdrawalPage.USD')}}, {{$t('depositWithdrawalPage.JPY')}}, {{$t('depositWithdrawalPage.CHF')}}, {{$t('depositWithdrawalPage.GBP')}}, {{$t('depositWithdrawalPage.AUD')}}</td>
							<td>${{$t('depositWithdrawalPage.zero')}}</td>
							<td>${{$t('depositWithdrawalPage.zero')}}</td>
							<td>{{$t('depositWithdrawalPage.twoToThreeBusinessDays')}}</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">{{$t('depositWithdrawalPage.fundCaps')}}</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e5.webp" alt="AAAFx" title="AAAFx" width="154" height="18" class="fluid-img" /> </td>
							<td>{{$t('depositWithdrawalPage.WorldWide')}}</td>
							<td>{{$t('depositWithdrawalPage.MY')}}, {{$t('depositWithdrawalPage.TH')}}, {{$t('depositWithdrawalPage.ID')}}, {{$t('depositWithdrawalPage.PH')}}, {{$t('depositWithdrawalPage.VN')}}</td>
							<td>${{$t('depositWithdrawalPage.zero')}}</td>
							<td>${{$t('depositWithdrawalPage.zero')}}</td>
							<td>{{$t('depositWithdrawalPage.instantCaps')}}</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">{{$t('depositWithdrawalPage.fundCaps')}}</a> </td>
						</tr>
					</table>
				</div>
				<div class="title-holder t-left wow fadeIn">
					<p>{{$t('depositWithdrawalPage.AdditionalInformation')}}: <br><br>
					1.	{{$t('depositWithdrawalPage.AdditionalInformationPointOne')}}
					<br><br>
					2.	{{$t('depositWithdrawalPage.AdditionalInformationPointTwo')}}
					<br><br>
					3.	{{$t('depositWithdrawalPage.AdditionalInformationPointThree')}}
					</p>
				</div>
				<div class="btn-holder t-center wow fadeIn">
					<a :href="siteurl2" target="_blank" class="btn btn-yellow">{{$t('depositWithdrawalPage.CapsOpenLiveAccount')}}</a>
					<a :href="siteurl2" target="_blank" class="btn btn-blue">{{$t('depositWithdrawalPage.CapsOpenDemoAccount')}}</a>
				</div>
			</div>
		</div>
		<div class="section deposits-holder" v-else-if="showtab == 'deposit'">
			<div class="wrapper">
				<div class="title-holder t-center wow fadeIn">
					<h3>{{$t('depositWithdrawalPage.DepositUpperCase')}}</h3>
					<p>{{$t('depositWithdrawalPage.DepositDesc')}}</p>
				</div>
				<div class="title-holder t-left wow fadeIn">
					<p>{{$t('depositWithdrawalPage.Steps_to_make_a_deposit')}}:  <br>
					1. {{$t('depositWithdrawalPage.Steps_to_make_a_deposit_pointOne')}} <br>
					2. {{$t('depositWithdrawalPage.Steps_to_make_a_deposit_pointOne')}} <br>
					</p>
				</div>
				<div class="deposit-nav">
					<ul>
						<li class="active"> <a v-on:click="showtab = 'deposit'" href="#" >{{$t('depositWithdrawalPage.Deposit')}}</a> </li>
						<li > <a href="#" v-on:click="showtab = 'withdrawal'"  >{{$t('depositWithdrawalPage.Withdrawals')}}</a> </li>
					</ul>
				</div>
				<!-- <div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>{{$t('depositWithdrawalPage.BankCards')}}</th>
							<th>{{$t('depositWithdrawalPage.Region')}}</th>
							<th>{{$t('depositWithdrawalPage.Currencies')}}</th>
							<th>{{$t('depositWithdrawalPage.MinDeposit')}}</th>
							<th>{{$t('depositWithdrawalPage.ServiceFee')}}</th>
							<th>{{$t('depositWithdrawalPage.ProcessingTime')}}</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/dep1.webp" alt="AAAFx" title="AAAFx" width="105" height="31" class="fluid-img" /> </td>
							<td>{{$t('depositWithdrawalPage.WorldWide')}}</td>
							<td>{{$t('depositWithdrawalPage.EUR')}}, {{$t('depositWithdrawalPage.USD')}}, {{$t('depositWithdrawalPage.CHF')}}, {{$t('depositWithdrawalPage.GBP')}}</td>
							<td>${{$t('depositWithdrawalPage.ten')}}</td>
							<td>${{$t('depositWithdrawalPage.zero')}}</td>
							<td>{{$t('depositWithdrawalPage.instantCaps')}}</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">{{$t('depositWithdrawalPage.fundCaps')}}</a> </td>
						</tr>
					</table>
				</div> -->
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>{{$t('depositWithdrawalPage.Bitcoin')}}</th>
							<th>{{$t('depositWithdrawalPage.Region')}}</th>
							<th>{{$t('depositWithdrawalPage.Currencies')}}</th>
							<th>{{$t('depositWithdrawalPage.MinDeposit')}}</th>
							<th>{{$t('depositWithdrawalPage.ServiceFee')}}</th>
							<th>{{$t('depositWithdrawalPage.ProcessingTime')}}</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/e8.webp" alt="AAAFx" title="AAAFx" width="123" height="26" class="fluid-img" /> </td>
							<td>{{$t('depositWithdrawalPage.WorldWide')}}</td>
							<td>{{$t('depositWithdrawalPage.BTC')}}</td>
							<td>${{$t('depositWithdrawalPage.ten')}}</td>
							<td>${{$t('depositWithdrawalPage.zero')}}</td>
							<td>{{$t('depositWithdrawalPage.uptoThirtyMin')}}*</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">{{$t('depositWithdrawalPage.fundCaps')}}</a> </td>
						</tr>
					</table>
				</div>
				<!-- <div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>{{$t('depositWithdrawalPage.BankWire')}}</th>
							<th>{{$t('depositWithdrawalPage.Region')}}</th>
							<th>{{$t('depositWithdrawalPage.Currencies')}}</th>
							<th>{{$t('depositWithdrawalPage.MinDeposit')}}</th>
							<th>{{$t('depositWithdrawalPage.ServiceFee')}}</th>
							<th>{{$t('depositWithdrawalPage.ProcessingTime')}}</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td @click="showinfo = 'eurobank'"><img src="assets/images/dep2.webp" alt="AAAFx" title="AAAFx" width="94" height="28" class="fluid-img" /> </td>
							<td>{{$t('depositWithdrawalPage.WorldWide')}}</td>
							<td>{{$t('depositWithdrawalPage.EUR')}}, {{$t('depositWithdrawalPage.USD')}}, {{$t('depositWithdrawalPage.JPY')}}, {{$t('depositWithdrawalPage.GBP')}}, {{$t('depositWithdrawalPage.AUD')}}, {{$t('depositWithdrawalPage.CHF')}}, {{$t('depositWithdrawalPage.BGN')}}</td>
							<td>${{$t('depositWithdrawalPage.ten')}}</td>
							<td>${{$t('depositWithdrawalPage.zero')}}</td>
							<td>{{$t('depositWithdrawalPage.twoToThreeBusinessDays')}}</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">{{$t('depositWithdrawalPage.fundCaps')}}</a> </td>
						</tr>
					</table>
				</div> -->
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>{{$t('depositWithdrawalPage.e_wallets')}}</th>
							<th>{{$t('depositWithdrawalPage.Region')}}</th>
							<th>{{$t('depositWithdrawalPage.Currencies')}}</th>
							<th>{{$t('depositWithdrawalPage.MinDeposit')}}</th>
							<th>{{$t('depositWithdrawalPage.ServiceFee')}}</th>
							<th>{{$t('depositWithdrawalPage.ProcessingTime')}}</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/e4.webp" alt="AAAFx" title="AAAFx" width="59" height="21" class="fluid-img" /> </td>
							<td>{{$t('depositWithdrawalPage.WorldWide')}}</td>
							<td>{{$t('depositWithdrawalPage.EUR')}}, {{$t('depositWithdrawalPage.USD')}}, {{$t('depositWithdrawalPage.JPY')}}, {{$t('depositWithdrawalPage.CHF')}}, {{$t('depositWithdrawalPage.GBP')}}, {{$t('depositWithdrawalPage.AUD')}}</td>
							<td>${{$t('depositWithdrawalPage.ten')}}</td>
							<td>${{$t('depositWithdrawalPage.zero')}}</td>
							<td>{{$t('depositWithdrawalPage.twoToThreeBusinessDays')}}</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">{{$t('depositWithdrawalPage.fundCaps')}}</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e5.webp" alt="AAAFx" title="AAAFx" width="154" height="18" class="fluid-img" /> </td>
							<td>{{$t('depositWithdrawalPage.WorldWide')}}</td>
							<td>{{$t('depositWithdrawalPage.EUR')}}, {{$t('depositWithdrawalPage.USD')}}, {{$t('depositWithdrawalPage.JPY')}}, {{$t('depositWithdrawalPage.GBP')}}, {{$t('depositWithdrawalPage.AUD')}}, {{$t('depositWithdrawalPage.CHF')}}</td>
							<td>${{$t('depositWithdrawalPage.ten')}}</td>
							<td>${{$t('depositWithdrawalPage.zero')}}</td>
							<td>{{$t('depositWithdrawalPage.instantCaps')}}</td>
							<td><a :href="siteurl" target="_blank" class="btn btn-blue">{{$t('depositWithdrawalPage.fundCaps')}}</a> </td>
						</tr>
					</table>
				</div>
				<div class="title-holder t-left">
					<p>* {{$t('depositWithdrawalPage.depositNoteOne')}}
						<br><br>
						* {{$t('depositWithdrawalPage.depositNoteTwo')}}
					</p>
				</div>

				<!-- Modal content -->
				<!---end--->
				<div class="btn-holder t-center wow fadeIn">
					<a :href="siteurl2" target="_blank" class="btn btn-yellow">{{$t('depositWithdrawalPage.CapsOpenLiveAccount')}}</a>
					<a :href="siteurl2" target="_blank" class="btn btn-blue">{{$t('depositWithdrawalPage.CapsOpenDemoAccount')}}</a>
				</div>

			</div>
		</div>
		<div class="modal" :class="[{'show' : showinfo == 'eurobank'}]">
			<div class="modal-content">
				<div class="close" @click="showinfo = ''"><vue-feather type="x"></vue-feather></div>
				<h3>{{$t('depositWithdrawalPage.EuroBankCaps')}}</h3>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<td>
								<span>{{$t('depositWithdrawalPage.Currency')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.USD')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.SwiftCode')}} : {{$t('depositWithdrawalPage.ProvidedSwiftCode')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.BeneficiaryName')}}: {{$t('depositWithdrawalPage.ProvidedBeneficiaryName')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.IBAN')}}: {{$t('depositWithdrawalPage.IBANCodeOne')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.Address')}}: {{$t('depositWithdrawalPage.ProvidedAddress')}}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>{{$t('depositWithdrawalPage.Currency')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.EUR')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.SwiftCode')}} : {{$t('depositWithdrawalPage.ProvidedSwiftCode')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.BeneficiaryName')}}: {{$t('depositWithdrawalPage.ProvidedBeneficiaryName')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.IBAN')}}: {{$t('depositWithdrawalPage.IBANCodeTwo')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.Address')}}: {{$t('depositWithdrawalPage.ProvidedAddress')}}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>{{$t('depositWithdrawalPage.Currency')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.GBP')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.SwiftCode')}} : {{$t('depositWithdrawalPage.ProvidedSwiftCode')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.BeneficiaryName')}}: {{$t('depositWithdrawalPage.ProvidedBeneficiaryName')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.IBAN')}}: {{$t('depositWithdrawalPage.IBANCodeThree')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.Address')}}: {{$t('depositWithdrawalPage.ProvidedAddress')}}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>{{$t('depositWithdrawalPage.Currency')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.JPY')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.SwiftCode')}} : {{$t('depositWithdrawalPage.ProvidedSwiftCode')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.BeneficiaryName')}}: {{$t('depositWithdrawalPage.ProvidedBeneficiaryName')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.IBAN')}}: {{$t('depositWithdrawalPage.IBANCodeFour')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.Address')}}: {{$t('depositWithdrawalPage.ProvidedAddress')}}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>{{$t('depositWithdrawalPage.Currency')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.CHF')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.SwiftCode')}} : {{$t('depositWithdrawalPage.ProvidedSwiftCode')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.BeneficiaryName')}}: {{$t('depositWithdrawalPage.ProvidedBeneficiaryName')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.IBAN')}}: {{$t('depositWithdrawalPage.IBANCodeFive')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.Address')}}: {{$t('depositWithdrawalPage.ProvidedAddress')}}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>{{$t('depositWithdrawalPage.Currency')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.AUD')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.SwiftCode')}} : {{$t('depositWithdrawalPage.ProvidedSwiftCode')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.BeneficiaryName')}}: {{$t('depositWithdrawalPage.ProvidedBeneficiaryName')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.IBAN')}}: {{$t('depositWithdrawalPage.IBANCodeSix')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.Address')}}: {{$t('depositWithdrawalPage.ProvidedAddress')}}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>{{$t('depositWithdrawalPage.Currency')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.BGN')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.SwiftCode')}} : {{$t('depositWithdrawalPage.ProvidedSwiftCode')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.BeneficiaryName')}}: {{$t('depositWithdrawalPage.ProvidedBeneficiaryName')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.IBAN')}}: {{$t('depositWithdrawalPage.IBANCodeSeven')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.Address')}}: {{$t('depositWithdrawalPage.ProvidedAddress')}}</span>
							</td>
						</tr>
					</table>
				</div>
				<div class="warrning">
						<h5>{{$t('depositWithdrawalPage.Warning')}}!</h5>
						<p>{{$t('depositWithdrawalPage.WarningMessage')}}</p>
				</div>
			</div>
		</div>
		<div class="modal" :class="[{'show' : showinfo == 'Swissquote'}]">
			<div class="modal-content">
				<div class="close" @click="showinfo = ''"><vue-feather type="x"></vue-feather></div>
				<h3>{{$t('depositWithdrawalPage.swissubebankSA')}}</h3>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>&nbsp;</th>
							<th>&nbsp;</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td>
								<span>{{$t('depositWithdrawalPage.Currency')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.EUR')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.SwiftCode')}} : {{$t('depositWithdrawalPage.ProvidedSwiftCodeTwo')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.BeneficiaryName')}}: {{$t('depositWithdrawalPage.ProvidedBeneficiaryNameTwo')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.IBAN')}}: {{$t('depositWithdrawalPage.IBANCodeEight')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.Address')}}: {{$t('depositWithdrawalPage.ProvidedAddressTwo')}}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>{{$t('depositWithdrawalPage.Currency')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.EUR')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.SwiftCode')}} : {{$t('depositWithdrawalPage.ProvidedSwiftCodeTwo')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.BeneficiaryName')}}: {{$t('depositWithdrawalPage.ProvidedBeneficiaryNameTwo')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.IBAN')}}: {{$t('depositWithdrawalPage.IBANCodeEight')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.Address')}}: {{$t('depositWithdrawalPage.ProvidedAddressTwo')}}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>{{$t('depositWithdrawalPage.Currency')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.GBP')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.SwiftCode')}} : {{$t('depositWithdrawalPage.ProvidedSwiftCodeTwo')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.BeneficiaryName')}}: {{$t('depositWithdrawalPage.ProvidedBeneficiaryNameTwo')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.IBAN')}}: {{$t('depositWithdrawalPage.IBANCodeEight')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.Address')}}: {{$t('depositWithdrawalPage.ProvidedAddressTwo')}}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>{{$t('depositWithdrawalPage.Currency')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.JPY')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.SwiftCode')}} : {{$t('depositWithdrawalPage.ProvidedSwiftCodeTwo')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.BeneficiaryName')}}: {{$t('depositWithdrawalPage.ProvidedBeneficiaryNameTwo')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.IBAN')}}: {{$t('depositWithdrawalPage.IBANCodeEight')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.Address')}}: {{$t('depositWithdrawalPage.ProvidedAddressTwo')}}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>{{$t('depositWithdrawalPage.Currency')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.CHF')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.SwiftCode')}} : {{$t('depositWithdrawalPage.ProvidedSwiftCodeTwo')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.BeneficiaryName')}}: {{$t('depositWithdrawalPage.ProvidedBeneficiaryNameTwo')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.IBAN')}}: {{$t('depositWithdrawalPage.IBANCodeEight')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.Address')}}: {{$t('depositWithdrawalPage.ProvidedAddressTwo')}}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>{{$t('depositWithdrawalPage.Currency')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.AUD')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.SwiftCode')}} : {{$t('depositWithdrawalPage.ProvidedSwiftCodeTwo')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.BeneficiaryName')}}: {{$t('depositWithdrawalPage.ProvidedBeneficiaryNameTwo')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.IBAN')}}: {{$t('depositWithdrawalPage.IBANCodeEight')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.Address')}}: {{$t('depositWithdrawalPage.ProvidedAddressTwo')}}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>{{$t('depositWithdrawalPage.Currency')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.BGN')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.SwiftCode')}} : {{$t('depositWithdrawalPage.ProvidedSwiftCodeTwo')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.BeneficiaryName')}}: {{$t('depositWithdrawalPage.ProvidedBeneficiaryNameTwo')}}</span>
							</td>
							<td>
								<span>{{$t('depositWithdrawalPage.IBAN')}}: {{$t('depositWithdrawalPage.IBANCodeEight')}}</span>
								<span class="CurrName">{{$t('depositWithdrawalPage.Address')}}: {{$t('depositWithdrawalPage.ProvidedAddressTwo')}}</span>
							</td>
						</tr>
					</table>
				</div>
				<div class="warrning">
						<h5>{{$t('depositWithdrawalPage.Warning')}}!</h5>
						<p>{{$t('depositWithdrawalPage.WarningMessage')}}</p>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
export default {
name: "Home",
data(){
return {
	showtab: "deposit",
	siteurl : process.env.VUE_APP_CLIENT_PORTAL_URL,
    siteurl2 : process.env.VUE_APP_CLIENT_REGISTER_URL, 
    showinfo : ''
}
},
method:{
},
}
</script>